import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { useEffect, useState } from "react";



const Payment = () => {
const [amount, setAmount] = useState({
  currency: "KRW",
  value: 50_000,
});
const [ready, setReady] = useState(false);
const [widgets, setWidgets] = useState(null);
const [data, setData] = useState({ title: '', price: 0, name: '', email: '', phoneNumber: '', orderId: '' ,customerKey:'',config:''});
const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const prodClientKey = process.env.REACT_APP_TOSS_PROD_CLIENT_KEY;
useEffect(() => {
  const receiveMessage = async (event) => {
    try {
      // 받은 데이터를 JSON 형식으로 파싱
      const receivedData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
  
      // 특정 조건에 맞는 메시지만 처리하기
      if (receivedData && receivedData.title && receivedData.price) {
        // 여기서 원하는 작업 수행 (예: 결제 데이터 설정)
        console.log('Received payment data:', receivedData);
        setData(receivedData);
        setAmount({
          currency: "KRW",
          value: receivedData.price
        });
      } else {
        console.warn('Ignored message:', receivedData); // 원하는 메시지가 아니면 무시
      }
    } catch (error) {
      console.error('Failed to parse message data:', error);
    }
  };

  window.addEventListener('message', receiveMessage);
  return () => {
    window.removeEventListener('message', receiveMessage);
  };
}, []);
useEffect(() => {
  async function fetchPaymentWidgets() {
    // ------  결제위젯 초기화 ------
    if (!data.title || data.price === 0) return;  // 데이터 확인 후 위젯 로드
    const realClientKey = data.config === 'prod' ? prodClientKey : clientKey;
    const tossPayments = await loadTossPayments(realClientKey);
    // 회원 결제
    const widgets = tossPayments.widgets({
      customerKey: data.customerKey,
    });
    // 비회원 결제
    // const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });

    setWidgets(widgets);
  }
  if(data){
    fetchPaymentWidgets();
  }
  
}, [clientKey,data]);

useEffect(() => {
  async function renderPaymentWidgets() {
    if (widgets == null) {
      return;
    }
    // ------ 주문의 결제 금액 설정 ------
    await widgets.setAmount(amount);

    await Promise.all([
      // ------  결제 UI 렌더링 ------
      widgets.renderPaymentMethods({
        selector: "#payment-method",
        variantKey: "DEFAULT",
      }),
      // ------  이용약관 UI 렌더링 ------
      widgets.renderAgreement({
        selector: "#agreement",
        variantKey: "AGREEMENT",
      }),
    ]);

    setReady(true);
  }
  if(data){
    renderPaymentWidgets();
  }
  
}, [widgets,data]);

useEffect(() => {
  if (widgets == null) {
    return;
  }

  widgets.setAmount(amount);
}, [widgets, amount]);
  console.log('here??')
return (
  <div className="wrapper">
    <div className="box_section">
      {/* 결제 UI */}
      <div id="payment-method" />
      {/* 이용약관 UI */}
      <div id="agreement" />

      {/* 결제하기 버튼 */}
      <div style={{ marginTop: 20, padding:'20px' }}>
      <button
        style={{backgroundColor: '#7bc143', color: 'white', borderRadius: '5px', padding: '15px 20px', width: '100%', border: 'none', fontSize: '20px'}}
        disabled={!ready}
        onClick={async () => {
          try {
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
            await widgets.requestPayment({
              orderId: data.orderId,
              orderName: data.title || '도다 챌린지',
              successUrl: window.location.origin + "/success",
              failUrl: window.location.origin + "/fail",
              customerEmail: data.email,
              customerName: data.name,
              customerMobilePhone: data.phoneNumber,
            });
          } catch (error) {
            // 에러 처리하기
            console.error(error);
          }
        }}
      >
        결제하기
      </button>
      </div>
    </div>
  </div>
);
}

export default Payment;