
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Payment from './pages/Payment';
import Success from './pages/Success';
import Fail from './pages/Fail';
import Privacy from './pages/Privacy';
import TermsOfUse from './pages/TermsOfUse';
function App() {
  return (
    <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/fail" element={<Fail />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                </Routes>
   
    </Router>
  );

}

export default App;
